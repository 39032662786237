'use client';
import Image from 'next/image';
import React from 'react';
import Link from 'next/link';
import CTAButton from '../commons/CTAButton';
import Amity from '@/assets/images/amity.png';
import JECRC from '@/assets/images/jecrc.png';
import Mukand from '@/assets/images/mukand.png';
import JKLU from '@/assets/images/jklu.png';
import Manipal from '@/assets/images/manipal.png';
import Piet from '@/assets/images/piet.png';
import NSUT from '@/assets/images/netaji-university.png';
import SRM from '@/assets/images/SRM-university.png';
import AmityMumbai from '@/assets/images/amity-mumbai-university.png';

const OurPartners = () => {
  const partners = [
    Amity,
    JECRC,
    Mukand,
    JKLU,
    Manipal,
    Piet,
    NSUT,
    SRM,
    AmityMumbai,
  ];

  return (
    <div
      className="bg-gradient-to-r from-[#ccffe5] to-[#fff5e5] md:py-[60px] py-4 text-center"
      style={{
        background:
          'linear-gradient(90deg, #E2FFF4 0%, #EBFBF3 49.77%, #FFF5F2 100%)',
      }}
    >
      {/* Title */}
      <div className="md:space-y-4 md:mb-12 mb-6">
        <h2 className="md:text-[40px] font-medium text-lg font-sans md:mb-4">
          Our Partners
        </h2>
        <p className="md:text-xl font-sans text-[#4F4F4F] md:tracking-wide tracking-wide text-sm font-normal md:mb-12 mb-6">
          Building a Future of Excellence Together with Our Partners!
        </p>
      </div>

      {/* Scrolling Cards Container 1 */}
      <div className="relative overflow-hidden max-w-[15rem] md:max-w-5xl mx-auto group">
        <div className="flex gap-2 md:gap-4 animate-infinite-scroll scroll-smooth hover:pause will-change-transform">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-[50px] h-[50px] md:w-[150px] md:h-[110px] rounded-lg md:rounded-2xl flex items-center justify-center"
            >
              <Image
                src={partner}
                alt={`Partner ${index}`}
                width={150} // Mobile size
                height={150} // Mobile size
                sizes="(max-width: 768px) 50px, 150px" // Responsive sizes
                quality={50} // Lower quality for better performance
                loading="lazy"
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,...`} // Ensure a valid base64 placeholder
                className="object-contain" // Use object-contain for predictable scaling
              />
            </div>
          ))}
        </div>
      </div>

      {/* Scrolling Cards Container 2 (opposite direction) */}
      <div className="relative overflow-hidden max-w-[13rem] md:max-w-3xl mx-auto">
        <div className="flex gap-2 md:gap-4 animate-infinite-scroll-reverse scroll-smooth hover:pause mt-8 will-change-transform">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-[50px] h-[50px] md:w-[150px] md:h-[110px] rounded-lg md:rounded-2xl flex items-center justify-center"
            >
              <Image
                src={partner}
                alt={`Partner ${index}`}
                width={150} // Mobile size
                height={150} // Mobile size
                sizes="(max-width: 768px) 50px, 150px" // Responsive sizes
                quality={50} // Lower quality for better performance
                loading="lazy"
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,...`} // Ensure a valid base64 placeholder
                className="object-contain" // Use object-contain for predictable scaling
              />
            </div>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className="md:space-y-6 space-y-1 md:mt-16 mt-8">
        <p className="md:text-4xl font-sans font-medium">
          Ready to Collaborate?
        </p>
        <p className="md:text-4xl font-sans font-medium">
          <span className="text-green-600">Partner with Us</span> for Success!
        </p>

        <Link href="/partner-with-us" className="flex justify-center">
          <CTAButton className="hidden md:block box-content px-14">
            Partner with Us
          </CTAButton>
          <CTAButton size="sm" className="md:hidden text-center">
            Partner with Us
          </CTAButton>
        </Link>
      </div>
    </div>
  );
};

export default OurPartners;





// 'use client';
// import Image from 'next/image';
// import React from 'react';
// import CTAButton from '../commons/CTAButton';
// import Amity from '@/assets/images/amity.png';
// import JECRC from '@/assets/images/jecrc.png';
// import Mukand from '@/assets/images/mukand.png';
// import JKLU from '@/assets/images/jklu.png';
// import Manipal from '@/assets/images/manipal.png';
// import Piet from '@/assets/images/piet.png';
// import NSUT from '@/assets/images/netaji-university.png';
// import SRM from '@/assets/images/SRM-university.png';
// import AmityMumbai from '@/assets/images/amity-mumbai-university.png';
// import Link from 'next/link';

// const OurPartners = () => {
//   const partners1 = [
//     Amity,
//     JECRC,
//     Mukand,
//     JKLU,
//     Manipal,
//     Piet,
//     NSUT,
//     SRM,
//     AmityMumbai,
//   ];
//   const partners2 = [
//     Amity,
//     JECRC,
//     Mukand,
//     JKLU,
//     Manipal,
//     Piet,
//     NSUT,
//     SRM,
//     AmityMumbai,
//   ];

//   return (
//     <div
//       className="bg-gradient-to-r from-[#ccffe5] to-[#fff5e5] md:py-[60px] py-4 text-center"
//       style={{
//         background:
//           'linear-gradient(90deg, #E2FFF4 0%, #EBFBF3 49.77%, #FFF5F2 100%)',
//       }}
//     >
//       {/* Title */}
//       <div className="md:space-y-4 md:mb-12 mb-6">
//         <h2 className="md:text-[40px] font-medium text-lg font-sans md:mb-4">
//           Our Partners
//         </h2>
//         <p className="md:text-xl font-sans text-[#4F4F4F] md:tracking-wide tracking-wide text-sm font-normal md:mb-12 mb-6">
//           Building a Future of Excellence Together with Our Partners!{' '}
//         </p>
//       </div>

//       {/* Scrolling Cards Container */}
//       <div className="relative overflow-hidden max-w-[15rem] md:max-w-5xl mx-auto group">
//         {/* Scrolling Container 1 */}
//         <div className="flex gap-2 md:gap-4 animate-scroll scroll-smooth hover:pause ">
//           {[...partners1, ...partners1].map((partner, index) => (
//             <Image
//               key={index}
//               className="flex-shrink-0 w-[50px] h-[50px] md:min-w-[150px] md:min-h-[110px] shadow-none rounded-lg md:rounded-2xl flex items-center object-fill justify-center"
//               src={partner}
//               alt={`Partner ${index}`}
//               width={150}
//               height={110}
//               quality={75}
//               loading="lazy"
//               placeholder="blur"
//               blurDataURL={`data:image/svg+xml;base64,...`} // Optional: add a base64-encoded placeholder image for better UX
//             />
//           ))}
//         </div>
//       </div>

//       <div className="relative overflow-hidden max-w-[13rem] md:max-w-3xl mx-auto">
//         {/* Scrolling Container 2 (opposite direction) */}
//         <div className="flex gap-2 md:gap-4 animate-scroll-reverse scroll-smooth hover:pause mt-8">
//           {[...partners2, ...partners2].map((partner, index) => (
//             <Image
//               key={index}
//               className="flex-shrink-0 w-[50px] h-[50px] md:min-w-[150px] md:min-h-[110px] shadow-none rounded-lg md:rounded-2xl flex items-center object-fill justify-center"
//               src={partner}
//               alt={`Partner ${index}`}
//               width={150}
//               height={110}
//               quality={75}
//               loading="lazy"
//               placeholder="blur"
//               blurDataURL={`data:image/svg+xml;base64,...`} // Optional: base64-encoded placeholder for better experience
//             />
//           ))}
//         </div>
//       </div>

//       {/* Call to Action */}
//       <div className="md:space-y-6 space-y-1 md:mt-16 mt-8">
//         <p className="md:text-4xl font-sans font-medium ">
//           Ready to Collaborate?
//         </p>
//         <p className="md:text-4xl font-sans font-medium ">
//           <span className="text-green-600">Partner with Us</span> for Success!
//         </p>

//         {/* Web Button */}
//         <Link href="/partner-with-us" className="flex justify-center">
//           <CTAButton className="hidden md:block box-content px-14 ">
//             Partner with Us
//           </CTAButton>

//           <CTAButton size="sm" className="md:hidden text-center">
//             Partner with Us
//           </CTAButton>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default OurPartners;




