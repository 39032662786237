'use client';
import React from 'react';
import Image from 'next/image';
import Hexagon1 from '@/assets/images/hexagon-one.png';
import Hexagon2 from '@/assets/images/hexagon-two.png';
import Hexagon3 from '@/assets/images/hexagon-three.png';
import Hexagon4 from '@/assets/images/hexagon-four.png';
import Icon1 from '@/assets/images/hexagonicon-one.png';
import Icon2 from '@/assets/images/hexagonicon-two.png';
import Icon3 from '@/assets/images/hexagonicon-four.png';
import Icon4 from '@/assets/images/hexagonicon-three.png';

const MobileHexagonCard = () => {
  return (
    <div className="flex justify-center flex-col items-center mt-[-4rem] py-2">
      {/* Green Hexagon - Jobs & Internship */}
      <div className="group relative w-[120px] h-[120px] top-[4.35rem] md:w-[180px] md:h-[200px]">
        <Image
          src={Hexagon1}
          alt="Jobs & Internship"
          fill
          className="absolute inset-0 z-0 object-cover"
        />
        <div className="relative z-10 flex flex-col items-center py-2 justify-between h-full ">
          <h1 className="text-xs font-sans font-bold text-center pt-2 ">
            Jobs & <br /> Internship
          </h1>
          <Image
            src={Icon1}
            className="pl-2 w-[90px] h-[60px] mx-auto mt-auto"
            alt="Jobs & Internship Icon"
          />
        </div>
      </div>

      {/* Blue Hexagon - Hackathons */}
      <div className="flex gap-[3.65rem]">
        <div className="group relative w-[120px] h-[120px] md:w-[180px] md:h-[200px]">
          <Image
            src={Hexagon4}
            alt="Hackathons"
            fill
            className="absolute inset-0 z-0 object-cover"
          />
          <div className="relative z-10 flex flex-col items-center justify-between h-full py-2 ">
            <h1 className="text-xs font-bold text-center pt-4">Hackathons</h1>
            <Image
              src={Icon4}
              className=" w-[90px] h-[60px] mx-auto mt-auto"
              alt="Hackathons Icon"
            />
          </div>
        </div>

        {/* Purple Hexagon - Startup & Funding */}
        <div className="group relative w-[120px] h-[120px] md:w-[180px] md:h-[200px]">
          <Image
            src={Hexagon2}
            alt="Startup & Funding"
            fill
            className="absolute inset-0 z-0 object-cover"
          />
          <div className="relative z-10 flex flex-col items-center justify-between h-full py-1">
            <h1 className="text-xs font-bold text-center pt-4">
              Startup & <br /> Funding
            </h1>
            <Image
              src={Icon2}
              className="w-[90px] h-[60px] mx-auto mt-auto"
              alt="Startup & Funding Icon"
            />
          </div>
        </div>
      </div>

      {/* Yellow Hexagon - Learn & Upskill */}
      <div className="group relative w-[120px] h-[120px] md:w-[180px] mb-[-4rem] bottom-[4.25rem] md:h-[200px]">
        <Image
          src={Hexagon3}
          alt="Learn & Upskill"
          fill
          className="absolute inset-0 z-0 object-cover"
        />
        <div className="relative z-10 flex flex-col items-center justify-between h-full py-2 ">
          <h1 className="text-xs font-bold text-center pt-3">
            Learn & <br /> Upskill
          </h1>
          <Image
            src={Icon3}
            className="w-[80px] h-[60px] mx-auto mt-auto"
            alt="Learn & Upskill Icon"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileHexagonCard;
