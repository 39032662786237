'use client';
import React, { useLayoutEffect, useState, useEffect } from 'react';
import HeroSection from '@/components/landing/HeroSection';
import Stats from '@/components/landing/Stats';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import CommunityCTA from './CommunityCTA';
import ExclusiveEventsCards from './ExclusiveEventsCards';
import CommunityEventsCards from './CommunityEventsCards';
import OurPartners from './OurPartners';
import OurCommunity from './OurCommunity';
import axios from 'axios';
import { baseURL } from '@/utils/BaseURL';

const LandingLayout = () => {
  const { data: session, status } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const [exclusiveEvents, setExclusiveEvents] = useState([]);
  const [communityEvents, setCommunityEvents] = useState([]);

  async function listOpportunitiesAPICall() {
    try {
      let headers = {};
      if (status === 'authenticated') {
        headers = {
          Authorization: `Bearer ${session?.accessToken}`,
        };
      }

      const params = {
        page: 1,
        page_size: 20,
        ordering: '-created_at',
        is_verified: true,
        is_active: true,
      };

      setIsLoading(true);
      const response = await axios.get(`${baseURL}/opportunity/list/`, {
        headers,
        params,
      });

      const events = response.data.data;
      setExclusiveEvents(events.filter(event => !event.is_external_event));
      setCommunityEvents(events.filter(event => event.is_external_event));
    } catch (error) {
      console.error('Error fetching opportunities:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    listOpportunitiesAPICall();
  }, [session, status]);

  return (
    <>
      <HeroSection session={session} status={status} />
      <div className="fixed bottom-10 right-10 z-[100] ">
        <Link
          href="/demo"
          className="px-3 py-3 rounded-2xl shadow-xl bg-black text-white z-50 hover:shadow-2xl transition duration-300 ease-in-out"
        >
          Request Demo
        </Link>
      </div>
      <CommunityCTA />
      {isLoading ? (
        <div className="flex justify-center items-center py-10">
          <p className="text-gray-500 text-lg">Loading events...</p>
        </div>
      ) : (
        <>
          <ExclusiveEventsCards opportunities={exclusiveEvents} />
          {/* <CommunityEventsCards opportunities={communityEvents} /> */}
          {/* <LearnWithUsCourses /> */}
          <Stats />
          <OurPartners />
          <OurCommunity />
        </>
      )}
      {/* <OurServices /> */}
      {/* <JoinCommunity /> */}
      {/* <Faq /> */}
    </>
  );
};

export default LandingLayout;
